import React, { useRef, useState } from 'react';
import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

function PasswordVisibilityToggle(props) {
  const inputRef = useRef(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleClick = event => {
    if (!inputRef.current) {
      inputRef.current = event.currentTarget
        .closest('.MuiInputBase-root')
        ?.querySelector('input');
    }

    if (inputRef.current) {
      const isPassword = inputRef.current.type === 'password';
      inputRef.current.type = isPassword ? 'text' : 'password';
      setIsPasswordVisible(!isPassword);
    }
  };

  return (
    <InputAdornment position="end">
      <Tooltip title={isPasswordVisible ? 'Hide Password' : 'Show Password'}>
        <IconButton onClick={handleClick} edge="end">
          {isPasswordVisible ? (
            <VisibilityOffOutlined />
          ) : (
            <VisibilityOutlined />
          )}
        </IconButton>
      </Tooltip>
    </InputAdornment>
  );
}

export default PasswordVisibilityToggle;
