// import axios from 'src/utils/axios';
import axios from 'axios';
import { store } from 'src';
import authService from 'src/services/authService';
import ajaxUtils from '../utils/ajaxUtils';
import { deleteRequest, downloadByGet, get, post, put } from '../utils/axios';
import {
  convertDataGridFilterToV6,
  dateToLocalDateTime,
  displayUpdateSnackbar,
  rebuildAffiliateGeneralSettingsDto,
  rebuildFileForm
} from '../utils/utils';
import { COMPANY_MERCHANT } from './companyActions';
import { SET_IS_LOADING } from './userInterfaceActions';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const PASSWORD_CHANGED = '@account/password-changed';
export const UPDATE_PROFILE_IMAGE = '@account/update-profile-image';
export const PERSONALIZATION = '@account/personalization';
export const DOC_REQ = '@account/doc-req';
export const DATAGRID_STATE = '@account/datagrid';
export const PAYMENT_METHOD = '@account/payment-method';

export const updateUserDataOnRedux = user => {
  store.dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      user
    }
  });
  store.dispatch({
    type: COMPANY_MERCHANT,
    payload: user.manager
  });
};

export const returnSimpleResponse = (request, setState, dispatch, history) => {
  request
    .then(response => {
      if (response !== undefined) {
        if (response.data.error === false) {
          const { token } = response.data;
          if (token) {
            const { redirectUrl, user } = response.data.data;
            authService.setSession(token);
            // eslint-disable-next-line no-use-before-define
            dispatch(setUserData(user));
            history.push(redirectUrl);
          } else {
            setState({
              message: response.data.message,
              isAlert: true,
              alertMode: 'success'
            });
          }
        } else {
          setState({
            message: response.data.message,
            isAlert: true,
            alertMode: 'error'
          });
        }
      }
    })
    .catch(err => {
      const errorObj = err.response.data;
      setState({
        message: errorObj.message,
        isAlert: true,
        alertMode: 'error'
      });
    });
};

export function loginWithToken(token) {
  return async dispatch => {
    try {
      await dispatch({ type: LOGOUT });
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginInWithToken(token);
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginWithEmailAndPassword(email, password);
      updateUserDataOnRedux(user);
      updateUserDataGridState(dispatch);
      return user;
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return dispatch =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout(userLoggedOut = false) {
  return async dispatch => {
    if (userLoggedOut) {
      post(`${ajaxUtils.userActions.logout}`);
    }
    authService.logout();

    dispatch({
      type: LOGOUT
    });

    // in case of logout reload application for loading the updating config
    // there isn't need for loading screen while logout
    dispatch({
      type: SET_IS_LOADING,
      payload: false
    });
  };
}

export function adminLogin(token) {
  return async dispatch => {
    try {
      await dispatch({ type: LOGOUT });
      dispatch({ type: LOGIN_REQUEST });

      const user = await authService.loginInWithToken(token);
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function registerAffiliate(data, scrollTop, history, setState, intl) {
  const request = post(ajaxUtils.userActions.registerAffiliate, data);
  request
    .then(response => {
      if (response !== undefined) {
        if (response.data.error === false) {
          if (response.data.token) {
            const accessToken = response.data.token;
            history.push(`/app/activation/${accessToken}`);
          } else {
            setState({
              message: intl.formatMessage({ id: response.data.message }),
              isAlert: true,
              alertMode: 'success'
            });
          }
        } else {
          setState({
            message: response.data.message,
            isAlert: true,
            alertMode: 'error'
          });
        }
      }
    })
    .catch(err => {
      const errorObj = err?.response?.data;
      setState({
        message: errorObj?.message,
        isAlert: true,
        alertMode: 'error'
      });
    })
    .finally(() => scrollTop());
}

export function updateGeneralProfile(settings) {
  settings = rebuildAffiliateGeneralSettingsDto(settings);
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const user = await put(
        `${ajaxUtils.userActions.settings.updateGeneralSettings}`,
        settings
      );
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          ...user
        }
      });
    } catch (error) {
      throw error;
    }
  };
}

export async function updatePasswordSettings(settings) {
  const response = await put(
    `${ajaxUtils.userActions.settings.updatePasswordSettings}`,
    settings
  );
  return response?.data;
}

export async function updateEmailSettings(settings) {
  const response = await post(
    `${ajaxUtils.userActions.settings.updateEmailSettings}`,
    settings
  );
  return response?.data;
}
export async function updatePostbackSettings(settings) {
  const response = await put(
    `${ajaxUtils.userActions.settings.updatePostbackSettings}`,
    settings
  );
  return response?.data;
}

const updateUserDataGridState = async dispatch => {
  try {
    const response = await get(`${ajaxUtils.datagrid.root}`);
    if (response.status == 200)
      await dispatch({
        type: DATAGRID_STATE,
        payload: response.data
      });
  } catch (error) {
    console.log(error);
  }
};

export const persistUserDataGridState = async state => {
  try {
    const response = await post(`${ajaxUtils.datagrid.root}`, state);
    return response;
  } catch (error) {
    console.log(error);
  } finally {
    updateUserDataGridState(store.dispatch);
  }
};

export const setUserDataGridStateAsDefault = async id => {
  try {
    const response = await put(`${ajaxUtils.datagrid.id(id)}`);
    if (response.status == 200) updateUserDataGridState(store.dispatch);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const deleteUserDataGridState = async id => {
  try {
    const response = await deleteRequest(`${ajaxUtils.datagrid.id(id)}`);
    if (response.status == 200) updateUserDataGridState(store.dispatch);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export async function getPostbackSettings() {
  const response = await get(
    `${ajaxUtils.userActions.settings.updatePostbackSettings}`
  );
  return response?.data;
}

export async function updateUserDetails(params, setDataChanged, url) {
  const response = await post(url, null, params);

  if (response !== undefined) {
    const { message, error } = response.data;
    if (response.data.error === false) {
      setDataChanged = {
        state: true,
        message
      };
    } else {
      setDataChanged = {
        state: false,
        message
      };
    }
  }
  return setDataChanged;
}

export function uploadProfileImage(data, snackbar, dispatch, intl) {
  put(
    `${ajaxUtils.userActions.settings.personalization}/pic`,
    rebuildFileForm(data, 'profilePic')
  )
    .then(response => {
      if (response !== undefined) {
        const { data, status } = response;
        if (status == 200) {
          displayUpdateSnackbar(snackbar, intl, true);
          dispatch({
            type: UPDATE_PROFILE_IMAGE,
            payload: data
          });
        } else {
          displayUpdateSnackbar(snackbar, intl, false);
        }
      }
    })
    .catch(err => {
      console.warn(err);
    });
}

export function removeProfileImage(snackbar, dispatch, intl) {
  deleteRequest(`${ajaxUtils.userActions.settings.personalization}/pic`)
    .then(response => {
      if (response !== undefined) {
        const { data, status } = response;
        if (status == 200) {
          displayUpdateSnackbar(snackbar, intl, true);
          dispatch({
            type: UPDATE_PROFILE_IMAGE,
            payload: data
          });
        } else {
          displayUpdateSnackbar(snackbar, intl, false);
        }
      }
    })
    .catch(err => {
      console.warn(err);
    });
}

/*
 * create download link
 * name file
 */
export async function downloadProfileImage(params) {
  const response = await downloadByGet(
    ajaxUtils.userActions.downloadProfileImage,
    params
  );
  return window.URL.createObjectURL(new Blob([response.data]));
}

export function forgotPasswordAction(email) {
  const request = axios({
    method: 'get',
    url: `${ajaxUtils.userActions.forgotPassword}`,
    headers: {},
    params: {
      email
    }
  });
  return setState => {
    returnSimpleResponse(request, setState);
  };
}

/*
 * validate user is account owner
 * name file
 */
export async function validateOwnerShip(
  snackbar,
  setShowDialog,
  showErrorMessage,
  params
) {
  const response = await post(
    ajaxUtils.userActions.validateOwnerShip,
    null,
    params
  );

  if (response !== undefined) {
    const { data, error, message } = response.data;
    if (error === false) {
      if (showErrorMessage.success) {
        snackbar(message, { variant: 'success' });
      }
      setShowDialog(false);
      return true;
    }
    if (showErrorMessage.error) {
      snackbar(message, { variant: 'error' });
    }

    return false;
  }
  return false;
}

export function changeForgottenPasswordAction(password, token) {
  const request = axios({
    method: 'get',
    url: `${ajaxUtils.userActions.changeForgottenPassword}`,
    headers: {},
    params: {
      password,
      token
    }
  });
  return setState => {
    request
      .then(response => {
        if (response !== undefined) {
          if (response.data.error === false) {
            setState({
              open: true,
              message: response.data.message,
              isAlert: false
            });
          } else {
            setState({
              open: false,
              message: response.data.message,
              isAlert: true
            });
          }
        }
      })
      .catch(err => {
        console.warn(err);
      });
  };
}

export function unsubscribePromoEmails(token) {
  const request = axios({
    method: 'get',
    url: `${ajaxUtils.userActions.unsubscribe}`,
    headers: {},
    params: {
      token
    }
  });
  return request;
}

export function getAllUsers(page, size, filters, sort, startDate, endDate) {
  let params = { page, size, startDate, endDate };

  if (sort) {
    params[`sortField`] = sort?.field;
    params[`sort`] = sort?.sort;
  }
  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      const filterString = convertDataGridFilterToV6(filter);

      const { columnField } = filter;
      params[`${columnField}`] = encodeURIComponent(filterString);
    });
  }
  return axios.get('/api/affiliate/mlm/users', { params });
}

export function updateProfile(update) {
  const request = axios.post('/api/account/profile', { update });

  return dispatch => {
    request.then(response =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data
      })
    );
  };
}

export function updatePersonalization(siteThemeOid, languageOid) {
  return async dispatch => {
    // eslint-disable-next-line no-useless-catch
    try {
      const dbPersonalization = await put(
        `${ajaxUtils.userActions.settings.personalization}`,
        null,
        { siteThemeOid, languageOid }
      );
      dispatch({
        type: PERSONALIZATION,
        payload: {
          ...dbPersonalization
        }
      });
    } catch (error) {
      throw error;
    }
  };
}

export const deleteAccount = async password => {
  const response = await put(
    ajaxUtils.userActions.settings.deleteAccount,
    null,
    {
      password
    }
  );
  return response?.data;
};

export const updateUserTerms = async (isAgreeTerms, isAgreePrivacy) => {
  const response = await put(ajaxUtils.userActions.settings.updateTerms, null, {
    agreedTermsAndConditions: isAgreeTerms,
    agreedPrivacyPolicy: isAgreePrivacy
  });
  return response?.data;
};

export const get2faStatus = async () => {
  const response = await get(`${ajaxUtils.twoFactor}/generateQrCode`);
  return response?.data;
};

export const toggle2fa = async token => {
  const response = await get(`${ajaxUtils.twoFactor}/toggle`, { token });
  return response?.data;
};

export function updateUserTheme(theme, saveSettings) {
  put(`${ajaxUtils.userActions.settings.updateTheme}`, null, {
    theme
  }).then(data => {
    saveSettings({ theme: theme });
  });
}

export const getAffiliateBalanceSheet = async (startDate, endDate) => {
  startDate = startDate ? dateToLocalDateTime(startDate) : null;
  endDate = endDate ? dateToLocalDateTime(endDate) : null;
  const response = await get(`/api/affiliate/mlm/balance`, {
    startDate,
    endDate
  });
  return response?.data;
};

export const getClientData = async () => {
  const response = await get(`${ajaxUtils.userActions.clientData}`);
  return response?.data;
};

export function updateUserPaymentMethodsRedux() {
  get(ajaxUtils.paymentMethods.get, {})
    .then(response => {
      store.dispatch({
        type: PAYMENT_METHOD,
        payload: response.data && response.data?.length > 0
      });
    })
    .catch(err => {
      console.warn(err);
    });
}

export const getIGamingFilteredDailyStats = async filters => {
  const startDate = dateToLocalDateTime(filters?.startDate);
  const endDate = dateToLocalDateTime(filters?.endDate);
  const { groupBy, groupBy2 } = filters;
  const response = await get(
    ajaxUtils.companyActions.statistics.dailyStats.iGaming,
    {
      startDate,
      endDate,
      groupBy,
      groupBy2
    }
  );
  return response?.data;
};

export async function getAffiliateRefTree() {
  const response = await get(`${ajaxUtils.userActions.affiliate.mlm}/tree`);
  return response?.data;
}

export async function getUserEmailPreferences() {
  const response = await get(
    `${ajaxUtils.userActions.settings.affiliateEmailPreferences}`
  );
  return response?.data;
}

export async function updateUserEmailPreferences(data) {
  const response = await put(
    `${ajaxUtils.userActions.settings.affiliateEmailPreferences}`,
    data
  );
  return response?.data;
}
