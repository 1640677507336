import { Typography } from '@mui/material';
import moment from 'moment/moment';
import { CheckCircle, MinusCircle } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { UserPermissionsEnum } from './enums/UserPermissionsEnum'; // eslint-disable-next-line react/prop-types

// eslint-disable-next-line react/prop-types
export function AllowedTrafficSource({ justifyContent }) {
  return (
    <span
      style={{
        color: 'rgb(73, 142, 68)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent,
        gap: '5px'
      }}
    >
      <CheckCircle />
      <Typography>
        <FormattedMessage id="allowed" />
      </Typography>
    </span>
  );
}

// eslint-disable-next-line react/prop-types
export function DisAllowedTrafficSource({ justifyContent }) {
  return (
    <span
      style={{
        color: 'rgb(185, 59, 47)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent,
        gap: '5px'
      }}
    >
      <MinusCircle />
      <Typography>
        <FormattedMessage id="disallowed" />
      </Typography>
    </span>
  );
}

export const DATE_PICKER_FORMAT = 'yyyy-MM-dd';

export const noImageRelativePath = '/no-image.png';

export const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export const DATE_DISPLAY_FORMAT = 'MM-dd-yyyy HH:mm:ss';
export const DATE_AMERICAN_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DATE_DEFUALT_FORMAT = 'DD-MM-YYYY HH:mm';

export const SettingsTabsEnum = {
  General: {
    value: 'general',
    label: 'general',
    icon: 'oui:integration-general'
  },
  Security: {
    value: 'security',
    label: 'security',
    icon: 'material-symbols:security',
    eightCapGuard: true
  },
  Privacy: {
    value: 'privacy',
    label: 'privacy',
    icon: 'icon-park-outline:personal-privacy',
    eightCapGuard: true
  },
  PostBack: {
    value: 'postback',
    label: 'Postback',
    icon: 'material-symbols:webhook',
    eightCapGuard: true
  },
  Documents: {
    value: 'documents',
    label: 'documents',
    icon: 'solar:documents-minimalistic-linear',
    eightCapGuard: true
  },
  PaymentMethods: {
    value: 'payment_methods',
    label: 'payment_methods',
    icon: 'ic:baseline-payment',
    eightCapGuard: true
  },
  ApiKeys: {
    value: 'api_keys',
    label: 'api_keys',
    icon: 'material-symbols:key-outline',
    scope: UserPermissionsEnum.API_KEYS.value,
    eightCapGuard: true
  },
  EmailPreferences: {
    value: 'email_preferences',
    label: 'affiliate_email_preferences',
    icon: 'mdi:email-lock-outline'
  }
};
export const DATE_FORMATS = [
  'DD-MM-YYYY',
  DATE_DEFUALT_FORMAT,
  'MM/DD/YYYY',
  'MM/DD/YYYY HH:mm',
  'MM/DD/YYYY HH:mm:ss',
  'DD/MM/YYYY',
  'DD/MM/YYYY HH:mm',
  'DD/MM/YYYY HH:mm:ss',
  'YYYY/MM/DD',
  'YYYY/MM/DD HH:mm',
  'YYYY/MM/DD HH:mm:ss',
  'MMMM DD, YYYY',
  'MMMM DD, YYYY HH:mm',
  'MMMM DD, YYYY HH:mm:ss',
  'DD MMMM YYYY',
  'DD MMMM YYYY HH:mm',
  'DD MMMM YYYY HH:mm:ss'
];

export const initialStartDateState = moment()
  .subtract(7, 'days')
  .startOf('day');
export const initialEndDateState = moment().endOf('day');
export const MISSING_2FA_TOKEN = 'MISSING_2FA_TOKEN';
export const INVALID_2FA_TOKEN = 'INVALID_2FA_TOKEN';
export const TERMS_AND_CONDITIONS_NEEDS_APPROVAL =
  'TERMS_AND_CONDITIONS_NEEDS_APPROVAL';
export const PRIVACY_POLICY_NEEDS_APPROVAL = 'PRIVACY_POLICY_NEEDS_APPROVAL';
